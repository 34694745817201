import React from 'react';
import 'react-native';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Find from "../components/Map";
import ContactForm from '../components/ContactForm';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainTitle, Welcome, Intro, Overview, Container, Box, Column, Row, ImageRow, ImageColumn, ImageBox, } from "./PagesElements";
import { FooterLink } from '../components/Footer/FooterStyles';

const Home = () => {
	return (
		<>
			<Navbar />
			<MainTitle>
				Independent Cycle Mechanic
			</MainTitle>
			<Welcome>
				Your Trusted Local Bike Mechanic - Expert Service for All Bikes, E-Bike's, and Suspension
			</Welcome>
			<Welcome style={{ fontSize: "20px" }}>
				At DJ Cycle Services, we take pride in providing high-quality, reliable bike services to our local cycling community. Whether you're a seasoned rider or just getting into cycling, our expert mechanic team is here to keep your bike in top condition, ensuring you have a smooth and safe ride every time. From routine tune-ups to complex repairs, we specialize in servicing all types of bicycles, including e-bikes, and offer a full range of maintenance options for your bike’s suspension and drivetrain.
			</Welcome>
			<Welcome style={{ fontSize: "20px" }}>
				No matter the make or model, we offer a full range of bike repairs, including brake adjustments, gear tuning, wheel truing, and drivetrain cleaning. We understand the importance of keeping your bike in peak performance, which is why we provide a thorough inspection and offer preventative maintenance that saves you from costly repairs down the road. Bring your bike in for a quick tune-up or an extensive overhaul—we’re here to ensure every ride is a great one. Stop by DJ Cycle Services today and let our friendly team of bike experts take care of your bike. We’re passionate about cycling and are dedicated to providing quality service that you can trust!
			</Welcome>
			<Welcome style={{ fontSize: "20px" }}>
				If you’re hitting the trails or tackling rough terrain, your bike’s suspension needs to be in prime condition. Our team is trained in servicing all suspension types, from air forks to coil shocks, making sure they’re tuned to your specific riding style and preferences. Whether you need a fork overhaul, shock maintenance, or a complete suspension tune-up, we’ve got you covered for all your Fox, RockShox, Ohlins, WP, KYB needs.
			</Welcome>
			<Welcome style={{ fontSize: "20px" }}>
				As e-bikes become more popular, we’ve made it a priority to specialize in Bosch e-bike motor servicing. Whether you need a diagnostic check, motor repair, or system update, we’re certified and experienced with Bosch motors and can get you back on the road with minimal downtime. Let us help keep your e-bike running like new, with the right care and attention from professionals who understand Bosch technology.
			</Welcome>
			<Welcome style={{ color: "#8B0000" }}>
				Services provided include<br />
			</Welcome>
			<Intro>
				<Overview>
					<li>Suspension Servicing (Shock, Fork, Seat Posts)</li>
					<li>E-Bike Diagnostics</li>
					<li>Motor Servicing and Repairs</li>
					<li>Wheel Building</li>
					<li>General Services + Routine Work</li>
					<li>Custom/Bespoke Build Options</li>
					<li>Off Road Motorcycle Suspension Servicing</li>
					<li>Surron and Talaria Servicing and Repairs</li>
				</Overview>
			</Intro>
			<Box>
				<Container>
					<Row>
						<Column>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/cytech_logo.png" alt="icon" style={{ height: 75, width: 125 }} />
						</Column>
						<Column>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/Bosch-eBike-Service-Logo-V2.jpg" alt="icon" style={{ height: 75, width: 150 }} />
						</Column>
					</Row>
				</Container>
			</Box>
			<Welcome style={{ color: "#8B0000" }}>
				Find Us In The Workshop
			</Welcome>
			<Intro>
				<Overview>
					6 Ollerton Road <br />
					Edwinstowe <br />
					Mansfield <br />
					NG21 9QG <br />
				</Overview>
			</Intro>
			<Intro>
				<Find />
			</Intro>
			<Welcome style={{ color: "#8B0000" }}>
				Opening Times
			</Welcome>
			<Intro>
				<Overview>
					Monday - Friday 10am - 5pm <br />
					Saturday 9am - 2pm <br />
					Sunday Closed <br />
				</Overview>
			</Intro>
			<Welcome style={{ color: "#8B0000" }}>
				Contact Us
			</Welcome>
			<Intro>
				<Overview>
					<FooterLink href="mailto: dale@jacksoncycleservices.co.uk"> <FontAwesomeIcon icon={faEnvelope} size="2x" /></FooterLink> &emsp; <FooterLink href="tel: 07513184486"> <FontAwesomeIcon icon={faPhone} size="2x" /></FooterLink>
				</Overview>
			</Intro>
			<ImageBox>
				<Container>
					<ImageRow>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/axs.jpg" alt="axs" style={{ width: "100%" }} />
						</ImageColumn>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/axs_2.jpg" alt="axs2" style={{ width: "100%" }} />
						</ImageColumn>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/dirt_bike.jpg" alt="offroadsuspension" style={{ width: "100%" }} />
						</ImageColumn>
					</ImageRow>
					<ImageRow>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/fox_shock.jpg" alt="foxshock" style={{ width: "100%" }} />
						</ImageColumn>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/fox_x2.jpg" alt="foxx2" style={{ width: "100%" }} />
						</ImageColumn>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/fox_x2_parts.jpg" alt="foxx2parts" style={{ width: "100%" }} />
						</ImageColumn>
					</ImageRow>
					<ImageRow>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/suspension.jpg" alt="suspension" style={{ width: "100%" }} />
						</ImageColumn>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/shock.jpg" alt="shock" style={{ width: "100%" }} />
						</ImageColumn>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/shock_2.jpg" alt="shock2" style={{ width: "100%" }} />
						</ImageColumn>
					</ImageRow>
					<ImageRow>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/rockshox.jpg" alt="rockshox" style={{ width: "100%", height: "100%" }} />
						</ImageColumn>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/rockshow_forks_shock.jpg" alt="rockshoxforksshock" style={{ width: "100%", height: "100%" }} />
						</ImageColumn>
						<ImageColumn>
							<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/shock_3.jpg" alt="fox" style={{ width: "100%", height: "100%" }} />
						</ImageColumn>
					</ImageRow>
				</Container>
			</ImageBox>
			<ContactForm />
			<Footer />
		</>
	);
};

export default Home;
